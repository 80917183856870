import { useEffect, useState } from 'react'

import { Checkbox, Icon, TextButton, Tooltip } from '@joor/design-system'
import isEmpty from 'lodash/isEmpty'
import { useSelector } from 'react-redux'
import styled from 'styled-components/macro'

import { toGlobalId } from 'utils/transformations/graphql'

import { useRenderToasters } from 'hooks/useRenderToasters'
import {
  getAccountId,
  isAccountTypeRetailer,
  isLiteRetailer as isLiteRetailerAccount,
  userIsInternal,
} from 'store/currentUser/selectors'

import { useUpdateRetailerPrimaryAccount } from './primaryAccount.mutations'
import { useUpdateAccountsPrivacy } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.mutations'
import { LiteRetailerAccountsType } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.queries'
import AccountsVisibilityModal from 'features/Navbar/NavbarLiteRetailer/modals/AccountsVisibilityModal/AccountsVisibilityModal'
import PrimaryAccountExplicationModal from 'features/Navbar/NavbarLiteRetailer/modals/PrimaryAccountExplanationModal/PrimaryAccountExplicationModal'
import { GrapheneAccount } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const TOOLTIP_CONTENT = (
  <span>
    A primary account is the one you’ll use for all activities on JOOR and want
    new brands to contact.
    <br />
    Once set, other accounts you’re linked to will remain accessible to you and
    any brands you are currently connected to.
    <br />
    However, new brands will not be able to find or connect to hidden accounts.
    You can change this later.
  </span>
)

const MODAL_CONTENT = (
  <span>
    A primary account is the one you’ll use for all activities on JOOR and want
    new brands to contact. Once set, other accounts you’re linked to will remain
    accessible to you and any brands you are currently connected to. However,
    new brands will not be able to find or connect to hidden accounts. You can
    change this later.
  </span>
)

const TOOLTIP_DISABLED_CONTENT = (
  <span>
    You do not have permission to set a primary account. Please contact our
    Product Support Team with further questions.
  </span>
)

const PrimaryAccountWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`

const StyledTextButton = styled(TextButton)`
  font-size: var(--size-font-small) !important;
  margin-top: 8px !important;
`

const PrimaryAccount = ({
  accounts,
  allowManageVisibility = false,
  checkboxId,
  displayModal = false,
  userAccounts,
}: {
  accounts: LiteRetailerAccountsType[]
  allowManageVisibility?: boolean
  checkboxId: string
  displayModal?: boolean
  userAccounts: GrapheneAccount[]
}) => {
  const [
    isPrimaryAccountExplicationModalOpen,
    setIsPrimarAccountExplicationModalOpen,
  ] = useState(false)
  const [
    isAccountVisibilityModalOpen,
    setIsAccountVisibilityModalOpen,
  ] = useState(false)
  const [isPrimaryAccount, setIsPrimaryAccount] = useState(false)

  const accountId = useSelector(getAccountId)
  const isInternalUser = useSelector(userIsInternal)
  const isRetailerAccount = useSelector(isAccountTypeRetailer)
  const isLiteRetailer = useSelector(isLiteRetailerAccount)

  const {
    updateRetailerPrimaryAccount,
    isUpdatingPrimaryAccountLoading,
  } = useUpdateRetailerPrimaryAccount()
  const { updateAccountsPrivacy } = useUpdateAccountsPrivacy()

  const { renderSuccessToast, renderErrorToast } = useRenderToasters()

  const globalRetailerAccountId = toGlobalId('Retailer', accountId) || ''
  const globalAccountId = toGlobalId('Account', accountId) || ''

  const [isPrimary, isPublic] = accounts.reduce(
    (acc, account) => {
      if (account.id === globalAccountId) {
        return [account.isPrimary, account.privacy]
      }
      return acc
    },
    [false, false],
  )

  useEffect(() => {
    setIsPrimaryAccount(!!isPrimary)
  }, [isPrimary])

  const isAssociatedWithBrandsOrProRetail = userAccounts.some(
    (userAccount) =>
      userAccount?.__typename === 'BrandAccount' ||
      userAccount?.assortmentPlanning,
  )

  const canCheckPrimaryAccount =
    (isRetailerAccount && isInternalUser) ||
    (isLiteRetailer && !isAssociatedWithBrandsOrProRetail)

  const tooltipOrModalMessage =
    !isPublic && !isPrimary
      ? TOOLTIP_DISABLED_CONTENT
      : displayModal
      ? MODAL_CONTENT
      : TOOLTIP_CONTENT

  const handleSelectPrimaryAccount = async (isPrimary: boolean) => {
    const toasterAction = {
      label: 'Manage Account Visibility',
      handler: () => setIsAccountVisibilityModalOpen(true),
    }

    const accountsToBeHidden = accounts
      //Filter Primary accounts and accounts already hidden
      .filter(
        (account) =>
          !account.isPrimary &&
          account.privacy &&
          account.id !== globalAccountId,
      )
      .map((account) => ({
        accountId: account.id,
        privacy: false,
      }))

    updateRetailerPrimaryAccount(globalRetailerAccountId, isPrimary).then(
      (resp) => {
        if (resp.errors && !isEmpty(resp.errors)) {
          renderErrorToast({
            title: 'Primary Account not updated',
            description: 'There was an error on the process. Please try again.',
          })
        } else if (accountsToBeHidden.length === 0) {
          renderSuccessToast({
            title: 'Primary Account Updated',
            description: isPrimary
              ? 'Your account has been successfully marked as primary.'
              : 'Your account has been successfully unmarked as primary.',
            action: toasterAction,
          })
        }
      },
    )
    setIsPrimaryAccount(isPrimary)

    if (isPrimary) {
      if (accountsToBeHidden.length > 0) {
        updateAccountsPrivacy(accountsToBeHidden).then((resp) => {
          if (resp.errors && !isEmpty(resp.errors)) {
            renderErrorToast({
              title: 'Account Settings Not Updated',
              description: 'There was an error hiding your selected accounts.',
            })
          } else {
            renderSuccessToast({
              title: 'Account Settings Updated',
              description:
                'All accounts you are linked to, that are not primary, have been hidden from new brands.',
              action: toasterAction,
            })
          }
        })
      }
    }
  }

  if (canCheckPrimaryAccount) {
    return (
      <>
        <PrimaryAccountWrapper>
          <Checkbox
            id={checkboxId}
            label="This is my Primary Account"
            onChange={(event) =>
              handleSelectPrimaryAccount(event.target.checked)
            }
            size="regular"
            checked={isPrimaryAccount}
            disabled={
              isUpdatingPrimaryAccountLoading || (!isPublic && !isPrimary)
            }
          />
          {displayModal ? (
            <Icon
              onClick={() => setIsPrimarAccountExplicationModalOpen(true)}
              iconName="question"
              iconSize="small"
              variant="ACTIVE"
            />
          ) : (
            <Tooltip content={tooltipOrModalMessage} defaultPlacement="right">
              <Icon iconName="information" iconSize="small" variant="ACTIVE" />
            </Tooltip>
          )}
        </PrimaryAccountWrapper>
        {userAccounts.length > 1 && allowManageVisibility && (
          <StyledTextButton
            id="retailer-primary-account__user-welcome__accounts-privacy-modal"
            onClick={() => setIsAccountVisibilityModalOpen(true)}
          >
            Manage Account Visibility
          </StyledTextButton>
        )}
        {isAccountVisibilityModalOpen && (
          <AccountsVisibilityModal
            isOpen={isAccountVisibilityModalOpen}
            onClose={() => setIsAccountVisibilityModalOpen(false)}
          />
        )}
        {isPrimaryAccountExplicationModalOpen && (
          <PrimaryAccountExplicationModal
            isOpen={isPrimaryAccountExplicationModalOpen}
            onClose={() => setIsPrimarAccountExplicationModalOpen(false)}
            message={tooltipOrModalMessage}
          />
        )}
      </>
    )
  }
  return null
}

export default PrimaryAccount
