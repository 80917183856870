import { lazy } from 'react'

// https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
// Separated by general domains (Brand, Retailer, Passport, etc.)

// Brand
export const NewOrderReviewPage = lazy(
  () => import('features/Hubs/Orders/Review/Review.page'),
)

export const ImportStylesPage = lazy(() => import('pages/ImportStylesPage'))

export const PaymentSettingsPage = lazy(
  () => import('pages/PaymentSettingsPage'),
)

export const BrandProfilePage = lazy(
  () => import('features/Brand/BrandProfile/BrandProfilePage'),
)

export const PublicBrandProfilePage = lazy(
  () => import('features/Brand/BrandProfile/PublicBrandProfilePage'),
)

export const DiscoveryDashboard = lazy(
  () => import('features/Brand/DiscoveryDashboard'),
)

export const ShowroomCollectionPage = lazy(
  () => import('pages/ShowroomCollectionPage'),
)

export const ShowroomCollectionListPage = lazy(
  () => import('pages/ShowroomCollectionListPage'),
)

export const ShowroomTemplateListPage = lazy(
  () => import('pages/ShowroomTemplateListPage'),
)

export const ShowroomStyleboardPage = lazy(
  () => import('pages/ShowroomStyleboardPage'),
)

export const ShowroomStyleboardListPage = lazy(
  () => import('pages/ShowroomStyleboardListPage'),
)

export const ShowroomManagementPage = lazy(
  () => import('containers/Showroom/ManageShowroom/ManageShowroom.page'),
)

export const BrandDashboardPage = lazy(() => import('pages/BrandDashboardPage'))

export const ProductCatalogPage = lazy(() => import('pages/ProductCatalogPage'))

export const PublicProductCatalogPage = lazy(
  () => import('pages/PublicProductCatalogPage'),
)

export const OrderListPage = lazy(() => import('pages/OrderListPage'))

export const CancelStylePage = lazy(
  () => import('pages/CancelReplaceStylePage/CancelReplaceStylePage'),
)

export const AnalyticsDashboardPage = lazy(
  () => import('pages/AnalyticsDashboardPage'),
)

export const AnalyticsEngagementConnectionsPage = lazy(
  () => import('pages/AnalyticsEngagementConnectionsPage'),
)

export const AnalyticsJoorPayReportPage = lazy(
  () => import('pages/AnalyticsJoorPayReportPage'),
)

export const PublicInvoicePage = lazy(
  () => import('features/Hubs/Orders/PublicInvoice/PublicInvoice.page'),
)

export const StorefrontPage = lazy(
  () => import('features/Storefront/StorefrontPage'),
)

export const ThirdPartySettingsPage = lazy(
  () => import('pages/ThirdPartySettingsPage'),
)

export const ExternalPaymentsPage = lazy(
  () => import('features/Hubs/Orders/ExternalPayments'),
)

export const ImportExportSettingsPage = lazy(
  () => import('features/IntegrationSettings/ExportTemplate/ExportTemplate'),
)

export const ListJobsPage = lazy(
  () => import('features/IntegrationSettings/ListJobs/ListJobs'),
)

export const IntegrationSettingsPage = lazy(
  () => import('features/IntegrationSettings/IntegrationSettings'),
)

export const InstagramLandingPage = lazy(
  () => import('features/Storefront/InstagramLandingPage'),
)

export const LinesheetsListViewPage = lazy(
  () => import('features/Linesheets/ListView/ListView.page'),
)

export const LinesheetsDetailViewPage = lazy(
  () => import('features/Linesheets/DetailView/DetailView.page'),
)
export const PublicPaymentsPage = lazy(
  () => import('features/Hubs/Orders/ExternalPayments/Payments'),
)

export const StylesPage = lazy(() => import('features/Styles/Styles'))

export const PremiumGroupPage = lazy(() => import('pages/PremiumGroupPage'))

export const UploadImagesPage = lazy(() => import('pages/UploadImagesPage'))

export const SwatchManagement = lazy(
  () => import('features/SwatchManagement/SwatchManagement'),
)

// Retailer
export const AttributeSettingsPage = lazy(
  () => import('pages/AttributeSettingsPage/AttributeSettingsPage'),
)

export const AssortmentSizingPage = lazy(
  () => import('pages/AssortmentSizingPage/AssortmentSizingPage'),
)

export const AssortmentReviewPage = lazy(
  () => import('pages/AssortmentReviewPage/AssortmentReviewPage'),
)

export const AssortmentListPage = lazy(
  () => import('pages/AssortmentListPage/AssortmentListPage'),
)

export const WorkspaceListPage = lazy(
  () => import('pages/AssortmentListPage/AssortmentListPage'),
)

export const DataSelfServePage = lazy(() => import('pages/DataSelfServePage'))

export const RetailDashboard = lazy(() => import('features/RetailDashboard'))

export const DiscoverBrands = lazy(() => import('features/DiscoverBrands'))

// Reporting
export const CustomReportsPage = lazy(() => import('pages/CustomReportsPage'))

export const CustomReportPage = lazy(() => import('pages/CustomReportPage'))

export const StyleDetailPage = lazy(() => import('pages/StyleDetailPage'))

export const ProductionPage = lazy(() => import('pages/ProductionPage'))

export const DeliveryReportPage = lazy(() => import('pages/DeliveryReportPage'))

export const SalesSummaryPage = lazy(() => import('pages/SalesSummaryPage'))

export const RetailSalesPage = lazy(() => import('pages/RetailSalesPage'))

export const InOrderReportPage = lazy(
  () => import('features/Hubs/Orders/InOrderReport/InOrderReport.page'),
)

export const PublicRetailerProfilePage = lazy(
  () => import('features/PublicRetailerProfile/PublicRetailerProfilePage'),
)

export const RetailerProfilePage = lazy(
  () => import('features/LeadGen/RetailerProfile/RetailerProfilePage'),
)

// Permissions
export const ManagePermissionsConfigurationPage = lazy(
  () => import('pages/ManagePermissionsConfigurationPage'),
)

export const ManagePermissionsPage = lazy(
  () => import('pages/ManagePermissionsPage'),
)

export const UserPermissionsPage = lazy(
  () => import('pages/UserPermissionsPage'),
)

export const PermissionsSettingsPage = lazy(
  () => import('pages/PermissionsSettingsPage'),
)

export const ManageRegionsPage = lazy(() => import('pages/ManageRegionsPage'))

// Passport
export const PassportLandingPage = lazy(
  () => import('features/Passport/PassportLandingPage'),
)

export const PassportQuickConnect = lazy(
  () => import('modals/PassportQuickConnect'),
)

export const PassportShowroomPage = lazy(
  () => import('features/Passport/PassportShowrooms/PassportShowroomPage'),
)

export const PassportEventLandingPage = lazy(
  () => import('features/Passport/EventLanding/EventLandingPage'),
)

export const PassportFavoritesPage = lazy(
  () => import('features/Passport/Favorites/PassportFavoritesPage'),
)

// Connection
export const ConnectionPage = lazy(() => import('pages/ConnectionPage'))

export const FindNewRetailersPage = lazy(
  () => import('features/LeadGen/FindNewRetailers/FindNewRetailersPage'),
)

export const ProspectsPage = lazy(
  () => import('features/LeadGen/Prospects/ProspectsPage'),
)

// Submissions
export const SubmissionsContactPage = lazy(
  () => import('features/LeadGen/Submissions/Contact/SubmissionsContactPage'),
)
export const SubmissionsViewPage = lazy(
  () => import('features/LeadGen/Submissions/SubmissionsViewPage'),
)

export const SubmissionsListPage = lazy(
  () => import('features/LeadGen/Submissions/List/SubmissionsListPage'),
)
export const ShopifyIntegrationPage = lazy(
  () => import('features/ShopifyIntegration/ShopifyIntegrationPage'),
)

export const ShopifyIntegrationConfigPage = lazy(
  () => import('features/ShopifyIntegration/ConfigPage/ConfigPage'),
)

export const ShopifyIntegrationTokenPage = lazy(
  () =>
    import(
      'features/ShopifyIntegration/InstallationPage/CompleteInstallationPage'
    ),
)

// Other
export const InviteUsersPage = lazy(
  () => import('pages/InviteUsersPage/InviteUsersPage'),
)

//Merge Orders
export const MergeOrdersPage = lazy(
  () => import('features/Hubs/Orders/MergeOrders/POSelection/POSelection.page'),
)
export const SelectProductsPage = lazy(
  () =>
    import(
      'features/Hubs/Orders/MergeOrders/SelectProducts/SelectProducts.page'
    ),
)

export const SsoSettingsPage = lazy(() => import('pages/SsoSettingsPage'))

export const ErrorPage = lazy(() => import('pages/ErrorPage'))

export const ZendeskSSOLoginPage = lazy(
  () => import('features/Zendesk/ZendeskSSOLogin.page'),
)
