import { useState } from 'react'

import { Icon, Search } from '@joor/design-system'

import { formatNameWithCode } from 'utils/formatters'
import { fromGlobalId } from 'utils/transformations/graphql'

import {
  AccountOption,
  OptionWrapper,
  SearchBar,
  SectionTitle,
  StyledTextButton,
  TitleWrapper,
} from '../../retailerAccountsDropdown.ui'
import AccountDropdownOption from '../AccountDropdownOption/AccountDropdownOption'
import { LiteRetailerAccountsType } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.queries'
import AccountsVisibilityModal from 'features/Navbar/NavbarLiteRetailer/modals/AccountsVisibilityModal/AccountsVisibilityModal'
import { useSetSelectedAccount } from 'features/Navbar/NavbarRevamp/components/AccountsDropdown/accountsDropdown.hooks'
import { RetailAccountsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import { GrapheneAccount } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const OtherAccountsSection = ({
  accounts,
  userAccounts,
}: {
  accounts: LiteRetailerAccountsType[]
  userAccounts: GrapheneAccount[]
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [searchText, setSearchText] = useState<string>('')
  const [
    isAccountVisibilityModalOpen,
    setIsAccountVisibilityModalOpen,
  ] = useState(false)
  const { setSelectedAccount } = useSetSelectedAccount()
  const canSearchFilter =
    Boolean(searchText?.length) && Boolean(accounts.length)
  const search = canSearchFilter ? searchText.toLowerCase() : ''

  return (
    <>
      <OptionWrapper data-testid={RetailAccountsDropdownIds.OtherAccounts}>
        <TitleWrapper onClick={() => setIsVisible(!isVisible)}>
          <SectionTitle>
            {`Other Accounts (${userAccounts.length})`}
          </SectionTitle>
          <Icon iconName={isVisible ? 'chevron-up' : 'chevron-down'} />
        </TitleWrapper>
        {isVisible && (
          <div>
            <StyledTextButton
              id={RetailAccountsDropdownIds.VisbilityModal}
              data-testid={RetailAccountsDropdownIds.VisbilityModal}
              onClick={() => setIsAccountVisibilityModalOpen(true)}
            >
              Manage Account Visibility
            </StyledTextButton>
            <SearchBar>
              <Search
                data-testid={RetailAccountsDropdownIds.AccountsSearchBar}
                placeholder={'Search by connected brands'}
                onSearch={({ searchText }: { searchText: string }) =>
                  setSearchText(searchText)
                }
                onKeyEnter={() => setSearchText(searchText)}
                value={searchText}
                incrementalSearch
              />
            </SearchBar>
            {userAccounts
              .filter((userAccount) => {
                const connectedAccounts =
                  accounts.find(
                    (account) =>
                      fromGlobalId(account.id).id ===
                      fromGlobalId(userAccount.id).id,
                  )?.connectedAccounts || []

                return (
                  userAccount.name.toLowerCase().includes(search) ||
                  connectedAccounts?.filter((brand) =>
                    brand?.profileName?.toLowerCase().includes(search),
                  )?.length > 0
                )
              })
              .map((userAccount) => {
                const accountId = fromGlobalId(userAccount.id).id
                const account = accounts.find(
                  (account) => accountId === fromGlobalId(account.id).id,
                )
                const accountTestId = `${RetailAccountsDropdownIds.AccountsOption}__${accountId}`

                return (
                  <AccountOption
                    key={accountTestId}
                    data-testid={accountTestId}
                  >
                    <AccountDropdownOption
                      onClick={() => setSelectedAccount(userAccount?.id)}
                      message={formatNameWithCode(userAccount?.name, accountId)}
                      connectedAccounts={account?.connectedAccounts}
                      privacy={!!account?.privacy}
                      createdAt={account?.createdAt?.toString() || ''}
                      isPrimary={!!account?.isPrimary}
                      hideVisibilityIcon={!account}
                      createdBy={
                        account?.accountCreationSource?.requestedByAccount &&
                        account?.accountCreationSource?.requestedByAccount
                          .id === account?.id
                          ? 'you'
                          : account?.accountCreationSource?.requestedByAccount
                              ?.name
                      }
                    />
                  </AccountOption>
                )
              })}
          </div>
        )}
      </OptionWrapper>
      {isAccountVisibilityModalOpen && (
        <AccountsVisibilityModal
          isOpen={isAccountVisibilityModalOpen}
          onClose={() => setIsAccountVisibilityModalOpen(false)}
        />
      )}
    </>
  )
}

export default OtherAccountsSection
