import styled, { css } from 'styled-components/macro'

interface MenuTextProps {
  isCurrentAccount: boolean
}

interface InfoProps {
  createdBy: boolean
}

export const MenuText = styled.div<MenuTextProps>`
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-alpha);
  letter-spacing: var(--letter-spacing-tighter);
  line-height: var(--line-height-16);
  padding: 8px;
  width: 100%;

  &:hover {
    border: ${(props) =>
      props.isCurrentAccount ? 'none' : '1px solid var(--color-primary-400)'};
    border-radius: 10px;
    padding: ${(props) => (props.isCurrentAccount ? '8px' : '7px')};
  }
`

export const sharedTextStyles = css`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-base);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-normal);
  font-weight: var(--font-weight-medium);
`

export const MenuTextMessage = styled.span`
  ${sharedTextStyles}
  color: var(--color-primary-800);
  text-align: left;
`

export const MenuTextMessageChecked = styled.span`
  ${sharedTextStyles}
  color: var(--color-secondary-800);
  text-align: left;
`

export const MenuTextMessageCheck = styled.div`
  ${sharedTextStyles}
  color: var(--color-secondary-800);
  text-align: center;
  display: flex;

  svg {
    width: 16px;
    height: 16px;
  }

  .checkmark > svg {
    --main-color: var(--color-secondary-800);
  }
`

export const MenuConnectedAccounts = styled.span`
  color: var(--color-primary-600);
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  letter-spacing: var(--letter-spacing-tighter);
  text-align: left;
  margin-top: 4px;
`

export const MenuTextAction = styled.button<MenuTextProps>`
  background-color: var(--color-primary-100);
  border: none;
  text-align: left;
  padding: 0;
  cursor: pointer;
  text-decoration-line: none;
  margin: auto 0;
  display: flex;
  line-height: var(--line-height-16);
  width: 100%;

  border: 1px solid var(--color-primary-200);
  border-radius: 10px;

  &.primary {
    border: 1px solid var(--color-accent-beta-700);
    border-radius: 10px;
  }
  ${(props) =>
    props.isCurrentAccount &&
    css`
      cursor: auto;
    `}
`
export const PrivacyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TextIconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`

export const PrimaryWrapper = styled.div`
  display: flex;
  align-items: center;
  color: var(--color-accent-beta-700);
  path {
    fill: var(--color-accent-beta-700) !important;
  }
`

export const CreationInfoContainer = styled.div<InfoProps>`
  margin-top: 4px;
  display: flex;
  margin-left: auto;
  justify-content: ${(props) => (props.createdBy ? 'space-between' : 'end')};
`

export const Info = styled.div`
  color: var(--color-primary-600);
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  line-height: var(--line-height-16);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`
