import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const NavbarRevampContainer = styled.div`
  * a {
    color: var(--color-secondary-100);
  }

  * a:hover {
    color: var(--color-secondary-100);
  }
`

export const NavbarHeader = styled.div`
  background-color: var(--color-primary-800);
  display: flex;
  position: fixed;
  margin: 0;
  width: 100%;
  top: 0;
  left: 0;
  right: auto;
  bottom: auto;
  z-index: 3010;
  height: 53px;
  font-size: var(--size-font-small);
  font-weight: var(--font-weight-medium);
  font-family: var(--font-family-alpha);
`

export const HomeLink = styled(Link)`
  border-bottom: none;
  padding: 13px 12px;
  img {
    width: 80px;
    height: auto;
  }
  &:hover {
    background: inherit;
  }
`
export const RightOptionsContainer = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: 12px;
`
