import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import { matchPath, useLocation } from 'react-router-dom'

import {
  getAccountId,
  isAccountTypeBrand,
  isLiteRetailer as isLiteRetailerSelector,
} from 'store/currentUser/selectors'

import {
  PASSPORT_PATHS,
  PUBLIC_PRODUCT_CATALOG,
  PUBLIC_RETAILER_PROFILE,
  SHOWROOM_PUBLIC_COLLECTION_VIEW,
  STOREFRONT_PATHS,
} from 'routes/paths'

import { NavbarLiteRetailer } from '../features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer'
import { isProductsPageContent } from './LegacyPage/LegacyPage.utils'
import PublicNavbar from 'features/Navbar/NavbarPublic/PublicNavbar'
import NavbarRevamp from 'features/Navbar/NavbarRevamp/NavbarRevamp.component'
import EditionNavbar from 'features/Storefront/EditionNavbar'

const PageNavbar = () => {
  const location = useLocation()
  const { wayfindingNavigation } = useFlags()

  const accountId = useSelector(getAccountId)
  const isBrandAccount = useSelector(isAccountTypeBrand)
  const isLiteRetailer = useSelector(isLiteRetailerSelector)

  const isPassport = matchPassportPaths(location.pathname)
  const isStorefront = matchStorefrontPaths(location.pathname)
  const isPublicShowroom = matchShowroomPaths(location.pathname)
  const isPublicCatalog = matchPublicCatalogPath(location.pathname)
  const isPublicRetailerProfile = matchPublicRetailerPath(location.pathname)

  if (isProductsPageContent()) return null

  if (
    isStorefront &&
    isStorefrontOwner({
      pathname: location.pathname,
      isStorefront,
      isBrandAccount,
      accountId,
    })
  ) {
    return <EditionNavbar />
  }

  if (
    isPublicShowroom ||
    isPublicCatalog ||
    isPassport ||
    isPublicRetailerProfile
  ) {
    return <PublicNavbar />
  }

  if (isLiteRetailer && wayfindingNavigation) {
    return <NavbarLiteRetailer />
  }

  return <NavbarRevamp />
}

// Utils for getting the conditions to get the navbar to show
const matchPassportPaths = (pathname: string) =>
  Boolean(
    matchPath(pathname, {
      path: PASSPORT_PATHS,
      exact: true,
    }),
  )

const matchStorefrontPaths = (pathname: string) =>
  Boolean(
    matchPath(pathname, {
      path: STOREFRONT_PATHS,
      exact: true,
    }),
  )

const matchShowroomPaths = (pathname: string) =>
  Boolean(
    matchPath(pathname, {
      path: SHOWROOM_PUBLIC_COLLECTION_VIEW,
      exact: true,
    }),
  )

const matchPublicCatalogPath = (pathname: string) =>
  Boolean(
    matchPath(pathname, {
      path: PUBLIC_PRODUCT_CATALOG,
      exact: true,
    }),
  )
const matchPublicRetailerPath = (pathname: string) =>
  Boolean(
    matchPath(pathname, {
      path: PUBLIC_RETAILER_PROFILE,
      exact: true,
    }),
  )

const isStorefrontOwner = ({
  pathname,
  isStorefront,
  isBrandAccount,
  accountId,
}: {
  pathname: string
  isStorefront: boolean
  isBrandAccount: boolean
  accountId: number
}) => {
  const storefrontPathParams = pathname?.split('/')
  const storefrontId =
    storefrontPathParams.length > 4
      ? storefrontPathParams?.[storefrontPathParams?.length - 2]
      : storefrontPathParams?.[storefrontPathParams?.length - 1]
  return (
    isStorefront && isBrandAccount && accountId === parseInt(storefrontId, 10)
  )
}

export default PageNavbar
