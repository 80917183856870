import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import { userCanFindConnections } from 'store/currentUser/selectors'

import {
  CONNECTION_REQUESTS,
  CURRENT_CONNECTIONS,
  DISCOVER_BRANDS,
  PASSPORT_FAVORITES,
  PASSPORT_LANDING,
  PENDING_CONNECTIONS,
  SUBMISSIONS_LIST,
} from 'routes/paths'

import { useNewConnectionsCount } from '../../../NavbarRevamp/components/ConnectionsMenuIcon/connectionsMenuOption.hooks'
import DropdownMenuGroup from '../../../NavbarRevamp/components/DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from '../../../NavbarRevamp/components/DropdownMenuTextLink/DropdownMenuTextLink'
import NavbarDropdown from '../../../NavbarRevamp/components/NavbarDropdown/NavbarDropdown'
import { useNewSubmissions } from '../../../NavbarRevamp/components/SubmissionsListButton/submissionsListButton.hooks'
import Trigger from '../../../NavbarRevamp/components/Trigger/Trigger'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { ExploreDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const NavbarDiscoverDropdownContainer = styled.div`
  display: flex;
`

const NavbarExploreDropdown = () => {
  const canFindConnections = useSelector(userCanFindConnections)
  const { newConnectionsCount } = useNewConnectionsCount()
  const { submissionsList } = useFlags()
  const newSubmissionsCount = useNewSubmissions(!submissionsList)

  const notificationsCount = newConnectionsCount + newSubmissionsCount

  return (
    <NavbarDiscoverDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.exploreBrands}
            id={ExploreDropdownIds.ExploreTrigger}
            count={notificationsCount}
          />
        }
      >
        <DropdownMenuGroup>
          <DropdownMenuTextLink
            to={CURRENT_CONNECTIONS}
            message={messages.connectedBrands}
            id={ExploreDropdownIds.ConnectedBrands}
            testId={ExploreDropdownIds.ConnectedBrands}
          />
          {submissionsList && (
            <DropdownMenuTextLink
              to={SUBMISSIONS_LIST}
              message={messages.interestedInYou}
              id={ExploreDropdownIds.InterestedInYou}
              testId={ExploreDropdownIds.InterestedInYou}
              count={newSubmissionsCount}
            />
          )}
          <DropdownMenuTextLink
            to={CONNECTION_REQUESTS}
            message={messages.connectionsRequestsReceived}
            id={ExploreDropdownIds.ConnectionRequestsReceived}
            testId={ExploreDropdownIds.ConnectionRequestsReceived}
            count={newConnectionsCount}
          />
          <DropdownMenuTextLink
            to={PENDING_CONNECTIONS}
            message={messages.connectionsRequestsSent}
            id={ExploreDropdownIds.ConnectionRequestsSent}
            testId={ExploreDropdownIds.ConnectionRequestsSent}
          />
        </DropdownMenuGroup>
        <DropdownMenuGroup>
          <DropdownMenuTextLink
            to={PASSPORT_FAVORITES}
            message={messages.favorites}
            id={ExploreDropdownIds.Favorites}
            testId={ExploreDropdownIds.Favorites}
          />
          {canFindConnections && (
            <DropdownMenuTextLink
              to={DISCOVER_BRANDS}
              message={messages.findNewBrands}
              id={ExploreDropdownIds.FindNewBrands}
              testId={ExploreDropdownIds.FindNewBrands}
            />
          )}
          <DropdownMenuTextLink
            to={PASSPORT_LANDING}
            message={messages.passport}
            id={ExploreDropdownIds.Passport}
            testId={ExploreDropdownIds.Passport}
          />
        </DropdownMenuGroup>
      </NavbarDropdown>
    </NavbarDiscoverDropdownContainer>
  )
}

export default NavbarExploreDropdown
