import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'

import {
  isAccountTypeBrand,
  isAccountTypeRetailer,
} from 'store/currentUser/selectors'

import { DATA_SELF_SERVE, EXPORT_TEMPLATES } from 'routes/paths'

import DropdownMenuTextLink from '../../DropdownMenuTextLink/DropdownMenuTextLink'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { SettingsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const IntegrationSettings = () => {
  const { retailIntegrationSettings } = useFlags()
  const isBrandAccount = useSelector(isAccountTypeBrand)
  const isRetailerAccount = useSelector(isAccountTypeRetailer)

  if (isBrandAccount) {
    return (
      <DropdownMenuTextLink
        to={EXPORT_TEMPLATES}
        message={messages.integrationSettings}
        id={SettingsDropdownIds.SettingsOptionIntegrations}
      />
    )
  } else if (isRetailerAccount && retailIntegrationSettings) {
    return (
      <DropdownMenuTextLink
        to={DATA_SELF_SERVE}
        message={messages.integrationSettings}
        id={SettingsDropdownIds.SettingsOptionIntegrations}
      />
    )
  }
  return null
}
export default IntegrationSettings
