import { formatNameWithCode } from 'utils/formatters'
import { fromGlobalId } from 'utils/transformations/graphql'

import {
  AccountOption,
  OptionWrapper,
  PrimaryAccountWrapper,
  SectionTitle,
} from '../../retailerAccountsDropdown.ui'
import AccountDropdownOption from '../AccountDropdownOption/AccountDropdownOption'
import CurrentAccountSettings from '../CurrentAccountSettings/CurrentAccountSettings'
import PrimaryAccount from '../PrimaryAccount/PrimaryAccount'
import { LiteRetailerAccountsType } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.queries'
import { RetailAccountsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import { GrapheneAccount } from 'features/Navbar/NavbarRevamp/navbarRevamp.types'

const CurrentAccountSection = ({
  accountId,
  accounts,
  userAccounts,
}: {
  accountId: number
  accounts: LiteRetailerAccountsType[]
  userAccounts: GrapheneAccount[]
}) => {
  const currentAccount = accounts.find(
    (account) => String(accountId) === fromGlobalId(account.id)?.id,
  )
  const currentAccountTestId = `${RetailAccountsDropdownIds.AccountsOption}__${currentAccount?.id}`
  return (
    <OptionWrapper data-testid={RetailAccountsDropdownIds.CurrentAccount}>
      <SectionTitle>Current Account</SectionTitle>
      <AccountOption
        key={currentAccountTestId}
        data-testid={currentAccountTestId}
      >
        <AccountDropdownOption
          message={formatNameWithCode(currentAccount?.name, accountId)}
          checked
          connectedAccounts={currentAccount?.connectedAccounts}
          privacy={!!currentAccount?.privacy}
          createdAt={currentAccount?.createdAt?.toString() || ''}
          isPrimary={!!currentAccount?.isPrimary}
          isCurrentAccount
          createdBy={
            currentAccount?.accountCreationSource?.requestedByAccount &&
            currentAccount?.accountCreationSource?.requestedByAccount?.id ===
              currentAccount?.id
              ? 'you'
              : currentAccount?.accountCreationSource?.requestedByAccount?.name
          }
        />
      </AccountOption>
      <PrimaryAccountWrapper>
        <PrimaryAccount
          accounts={accounts}
          userAccounts={userAccounts}
          checkboxId={RetailAccountsDropdownIds.PrimaryAccountCheckbox}
          displayModal
        />
      </PrimaryAccountWrapper>
      <CurrentAccountSettings />
    </OptionWrapper>
  )
}

export default CurrentAccountSection
