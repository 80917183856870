export const ASSORTMENT_LIST = '/assortments'
export const WORKSPACE_LIST = '/ra/workspaces'

export const ASSORTMENT_REVIEW = '/assortments/get_assortment/:id'
export const ASSORTMENT_REVIEW_UNITS = '/assortments/get_assortment/:id/units'
export const ASSORTMENT_REVIEW_EDIT_STYLES =
  '/assortments/get_assortment/:id/styles'
export const ASSORTMENT_REVIEW_EDIT_ATTRIBUTES =
  '/assortments/get_assortment/:id/attributes'

export const ASSORTMENT_SIZING = '/assortments/get_assortment/:id/sizing'

export const DISCOVER_BRANDS = '/ra/discover_brands'

export const ORDER_LIST = '/orders'
export const ORDERS_CART = '/orders/cart'
export const ORDER_REVIEW = '/orders/review/:orderId'
export const ORDER_MODIFY = '/orders/modify/:orderId'
export const NEW_ORDER_REVIEW = '/ra/orders/review/:id'
export const NEW_ORDER_REVIEW_CANCELLED_COLORS =
  '/ra/orders/review/:id/cancelled'
export const ORDERS_IN_PROGRESS = '/ra/orders/in_progress/:orderGroupId'
export const IN_ORDER_ALLOCATION_REPORT = '/ra/orders/reporting/allocation'
export const IN_ORDER_BUDGET_REPORT = '/ra/orders/reporting/budget'
export const CART = '/cart'

export const CANCEL_STYLE = '/orders/cancel_styles'

export const PUBLIC_INVOICE = '/r/invoices/pub/:uuid'
export const ADMIN_ACCOUNTS = '/admin/Accounts'
export const ADMIN_INVITE_USERS = '/admin/accounts/invite'

export const CONNECTION_REQUESTS = '/matches/requests'
export const CONNECTIONS = '/matches/current'
export const CURRENT_CONNECTIONS = '/matches/current'
export const FIND_BOUTIQUES = '/matches/find_boutiques'
export const FIND_NEW_RETAILERS = '/ra/find_new_retailers'
export const PROSPECTS = '/ra/prospects'
export const FIND_BRANDS = '/matches/find_brands'
export const PENDING_CONNECTIONS = '/matches/pending'
export const SHOP_BY_BRAND = '/matches/shop'
export const SHOP_BY_LINESHEET = '/collections/shop#dd=all'
export const BEST_SELLERS = '/styles/best_sellers'

export const CREATE_LINESHEET = '/collections/add'
export const PREVIEW_LINESHEET = '/collections/view/:collectionPath'
export const EDIT_LINESHEET = '/collections/edit/:linesheetId'
export const CREATE_STYLE = '/styles/add'
export const COPY_STYLE = '/styles/add?copy=:styleId'
export const INVENTORY = '/inventory'
export const STAGING = '/staged_styles/review'
export const STYLES = '/ra/styles'
export const STYLES_VIEW = '/styles/view/:styleId'
export const STYLES_NOT_FOUND_PATH = '/ra/styles-not-found'
export const STYLE_SYNC = '/styles/upload_sync'
export const UPLOAD_DATA = '/accounts/data_submission_form'
export const IMPORT_STYLES = '/styles/import'
export const EDIT_STYLE = '/styles/edit/:productId'
export const EDIT_STYLE_IN_COLLECTION = '/styles/edit/:productId/:collectionId'
export const DELETE_STYLE = '/styles/delete/:styleId'

export const INBOX = '/messages'
export const INVITATIONS = '/messages/invitation'
export const MESSAGES = '/messages'
export const SEND_MESSAGE = '/messages/#!compose'
export const SENT_MESSAGES = '/messages/sent'

export const ADMIN_LOGOUT = '/admin/users/logout'
export const INTEGRATIONS = '/integrations'
export const INVENTORY_SETTINGS = '/inventory/settings'
export const KELLWOOD_ADMIN = '/admin/kellwood'
export const LOGOUT = '/users/logout'
export const MY_SETTINGS = '/accounts/settings'
export const ORDER_SETTINGS = '/orders/settings'
export const RETAILER_SETTINGS = '/boutiques/settings'
export const ATTRIBUTE_SETTINGS = '/boutiques/attribute_settings'
export const RETAILER_PUBLIC_CONNECTION = '/boutiques/view'
export const RETAIL_PROFILE_MATCH_SELF_VIEW = '6'
export const RETAILER_VIEW_OWN_PROFILE =
  '/boutiques/view/:retailerId/:matchType?'
export const RLM_ADMIN = '/admin/rlm'
export const STYLE_SETTINGS = '/styles/settings'
export const STYLE_SYNC_SETTINGS = '/sync/style_setup'

export const MORE = '/reports'
export const PRODUCTION = '/reports/view/4'
export const REPORTS = '/reports'
export const SALES_BY_RETAILER = '/reports/view/3'
export const SALES_SUMMARY = '/reports/view/1'
export const STYLE_DETAIL = '/reports/view/2'
export const STYLE_DETAIL_ROLLUP = '/reports/view/11'

export const ACCOUNTS_HOME = '/accounts/home'
export const HOME = '/ra/home'

export const TERMS_OF_SERVICE = '/terms_conditions'
export const PRIVACY_POLICY = '/privacy_policy'
export const CONTACT_SUPPORT = 'mailto:support@jooraccess.com'

export const PAYMENT_SETTINGS = '/payments/settings'

export const PERMISSIONS_SETTINGS = '/ra/permissions/settings'
export const MANAGE_PERMISSIONS = '/ra/permissions/manage'
export const MANAGE_PERMISSIONS_CONFIGURATION_CREATE =
  '/ra/permissions/configuration'
export const MANAGE_PERMISSIONS_CONFIGURATION_EDIT =
  '/ra/permissions/configuration/:configurationId'
export const MANAGE_USER_PERMISSIONS = '/ra/permissions/manage/:userId'
export const MANAGE_REGIONS = '/ra/permissions/regions'

export const PREMIUM_GROUP = '/ra/premium_group'

export const DATA_SELF_SERVE = '/ra/data_self_serve'

export const SSO_SETTINGS = '/ra/sso_settings'

export const SIGN_IN = '/sign_in'
export const RESET_PASSWORD = '/password'
export const LEGACY_RETAIL_REGISTRATION = '/retail_registration'

// The /r/ route is for public pages
export const PASSPORT_LANDING = '/r/passport'
export const PASSPORT_FAVORITES = '/r/passport/favorites'
export const PUBLIC_BRAND_PROFILE = '/r/designers/view/:accountId/:matchType?'
export const PUBLIC_STOREFRONT = '/r/storefront/:accountId/:pageName?'
export const PUBLIC_INSTAGRAM_LANDING = '/r/instagram/:loginSuccess'
export const PUBLIC_NOT_FOUND = '/r/*'
export const PUBLIC_PRODUCT_CATALOG = '/r/products'
export const PUBLIC_LINESHEET_DETAIL = '/r/linesheet/:token'
export const PUBLIC_PAYMENTS_PAGE = '/r/payments/:externalPaymentUUID'
export const PUBLIC_RETAILER_PROFILE = '/r/profile/:accountId'

// The /ra/ route is for pages accessible only by authenticated users
export const PRODUCT_CATALOG = '/ra/products'
export const BRAND_PROFILE = '/ra/designers/view/:accountId/:matchType?'
export const DISCOVERY_DASHBOARD = '/ra/favorited-your-profile'
export const STOREFRONT = '/ra/storefront/:accountId/:pageName?'
export const LINESHEETS = '/ra/linesheets'
export const LINESHEETS_UPLOAD_IMAGES = '/ra/linesheets/upload-images'
export const NEW_CREATE_LINESHEET = '/ra/linesheet'
export const LINESHEET_DETAIL = '/ra/linesheet/:linesheetId?'
export const THIRD_PARTY_SETTINGS = '/ra/third-party-settings'
export const EXPORT_TEMPLATES = '/ra/integration-settings/export-templates'
export const LIST_JOBS = '/ra/integration-settings/jobs'
export const EXTERNAL_PAYMENTS = '/ra/external-payments/:action?'
export const INTEGRATION_SETTINGS_PAGE = '/r/integration-settings'
export const AUTHENTICATED_NOT_FOUND = '/ra/*'

export const PASSPORT_EVENT = `${PASSPORT_LANDING}/:eventName`
export const PASSPORT_SHOWROOM = `${PASSPORT_EVENT}/showrooms/:showroomName`
export const PASSPORT_PATHS = [
  PASSPORT_LANDING,
  PASSPORT_EVENT,
  PASSPORT_SHOWROOM,
]
export const PASSPORT_QUICK_CONNECT = '/ra/passport_quick_connect'

export const STOREFRONT_PATHS = [STOREFRONT]

export const NEW_SALES_SUMMARY = '/ra/reports/sales_summary'
export const NEW_STYLE_DETAIL = '/ra/reports/style_detail'
export const NEW_PRODUCTION = '/ra/reports/production'
export const RETAIL_SALES = '/ra/reports/retail_sales'
export const ANALYTICS_DASHBOARD = '/ra/analytics_dashboard'
export const ANALYTICS_ENGAGEMENT_CONNECTIONS_DASHBOARD = '/ra/ea_dashboard'
export const ANALYTICS_JOOR_PAY_REPORT_DASHBOARD =
  '/ra/joor_pay_report_dashboard'

export const DELIVERY_REPORT = '/ra/reports/delivery/:brandId'
export const CUSTOM_REPORTS = '/ra/reports/custom_reports'
export const CUSTOM_REPORT = '/ra/reports/custom_report/:reportId'

export const LOOKBOOKS = '/lookbooks'
export const PRESS = '/press/show'

export const SHOWROOM_TEMPLATE_LIST = '/ra/showroom/templates'
export const SHOWROOM_MANAGEMENT = '/ra/showroom/manage'
export const SHOWROOM_COLLECTION_ADD = '/ra/showroom/collections/add'
export const SHOWROOM_COLLECTION_VIEW = '/ra/showroom/collections/:id'
export const SHOWROOM_PUBLIC_COLLECTION_VIEW = '/r/showroom/collections/:id'
export const SHOWROOM_COLLECTION_LIST = '/ra/showroom/collections'
export const SHOWROOM_STYLEBOARD_ADD = '/ra/showroom/styleboards/add'
export const SHOWROOM_STYLEBOARD_VIEW = '/ra/showroom/styleboards/:id'
export const SHOWROOM_STYLEBOARD_LIST = '/ra/showroom/styleboards'

export const SHOPIFY_INTEGRATION = '/ra/shopify'
export const SHOPIFY_INTEGRATION_TOKEN = '/ra/shopify/token'
export const SHOPIFY_INTEGRATION_CONFIG = '/ra/shopify/config'

export const MANAGE_BRAND_PROFILE = '/designers/manage'
export const MANAGE_BRAND_JOOR_URL = '/designers/url'
export const PHOTO_GALLERY = '/uploads'

export const CONNECTION = '/ra/connections/:id'
export const MOVE_PRODUCTS_PO_SELECTION = '/ra/move_products'
export const MOVE_PRODUCTS_PRODUCTS_SELECTION =
  '/ra/move_products/select_products/:orderId'
export const RETAILER_PROFILE = '/ra/profile/:accountId'
export const SUBMISSIONS_CONTACT =
  '/ra/submissions/contact/:retailerId/:accountUserId?'
export const SUBMISSIONS_VIEW = '/ra/submissions/:submissionId'
export const SUBMISSIONS_LIST = '/ra/submissions'

export const ZENDESK_SSO_LOGIN = '/ra/zendesk/sso'

export const SWATCH_MANAGEMENT = '/ra/swatch-management'
