import { Icon, Text } from '@joor/design-system'
import { useSelector } from 'react-redux'

import { formatConnectedAccounts, formatDate } from 'utils/formatters'

import { getDateFormat } from 'store/currentUser/selectors'

import {
  CreationInfoContainer,
  Info,
  MenuConnectedAccounts,
  MenuText,
  MenuTextAction,
  MenuTextMessage,
  MenuTextMessageCheck,
  MenuTextMessageChecked,
  PrimaryWrapper,
  PrivacyWrapper,
  TextIconsWrapper,
} from './accountDropdownOption.ui'
import { ConnectedAccountType } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.queries'

interface IAccountDropdownOption {
  message: string
  connectedAccounts?: ConnectedAccountType[]
  className?: string
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  checked?: boolean
  privacy: boolean
  createdAt: string
  isPrimary: boolean
  isCurrentAccount?: boolean
  hideVisibilityIcon?: boolean
  createdBy?: string | null
}

const AccountDropdownOption = ({
  className,
  message,
  connectedAccounts = [],
  onClick,
  checked = false,
  isPrimary,
  createdAt,
  privacy,
  isCurrentAccount = false,
  hideVisibilityIcon = false,
  createdBy,
}: IAccountDropdownOption) => {
  const userDateFormat = useSelector(getDateFormat) as string
  const formattedDate = formatDate(createdAt, userDateFormat)

  return (
    <MenuTextAction
      className={`menuTextLink ${isPrimary ? 'primary' : ''}`}
      isCurrentAccount={isCurrentAccount}
    >
      <MenuText
        className={className}
        isCurrentAccount={isCurrentAccount}
        onClick={(event) => onClick && onClick(event)}
      >
        <div>
          {isPrimary && (
            <PrimaryWrapper>
              <Icon iconName="pushpin" plain iconSize="small" />
              <Text
                fontFamily="numeric"
                fontSize="extrasmall"
                color="accent-beta-700"
              >
                PRIMARY
              </Text>
            </PrimaryWrapper>
          )}
          {!checked ? (
            <PrivacyWrapper>
              <MenuTextMessage>{message}</MenuTextMessage>
              {!hideVisibilityIcon && (
                <Icon
                  iconName={privacy ? 'visibility-on' : 'visibility-off'}
                  iconSize="small"
                  color="primary-800"
                  plain
                />
              )}
            </PrivacyWrapper>
          ) : (
            <TextIconsWrapper>
              <PrivacyWrapper>
                <MenuTextMessageChecked>{message}</MenuTextMessageChecked>
              </PrivacyWrapper>
              <MenuTextMessageCheck>
                {!hideVisibilityIcon && (
                  <Icon
                    iconName={privacy ? 'visibility-on' : 'visibility-off'}
                    iconSize="small"
                    color="primary-800"
                    plain
                  />
                )}
                <Icon iconName="checkmark" plain className="checkmark" />
              </MenuTextMessageCheck>
            </TextIconsWrapper>
          )}
          {!!connectedAccounts.length && (
            <MenuConnectedAccounts>
              {formatConnectedAccounts(connectedAccounts)}
            </MenuConnectedAccounts>
          )}
          {(createdBy || createdAt) && (
            <CreationInfoContainer createdBy={Boolean(createdBy)}>
              {createdBy && <Info>Created by {createdBy}</Info>}
              <Info>
                {!createdBy && 'Created date:'} {formattedDate}
              </Info>
            </CreationInfoContainer>
          )}
        </div>
      </MenuText>
    </MenuTextAction>
  )
}

export default AccountDropdownOption
