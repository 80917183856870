import { formatNameWithCode } from 'utils/formatters'

import {
  AccountLabel,
  LogoContainer,
  StyledImagePlaceholder,
  StyledLogo,
  StyledPlaceholder,
} from '../../retailerAccountsDropdown.ui'
import Trigger from 'features/Navbar/NavbarRevamp/components/Trigger/Trigger'
import { RetailAccountsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const AccountMenuTrigger = ({
  accountId,
  accountName,
  logoUrl,
}: {
  accountId: number
  accountName: string
  logoUrl?: string
}) => (
  <Trigger
    id={RetailAccountsDropdownIds.AccountsTrigger}
    label={
      <>
        {logoUrl ? (
          <LogoContainer data-testid={RetailAccountsDropdownIds.Logo}>
            <StyledLogo src={logoUrl} alt="retailer-logo" />
          </LogoContainer>
        ) : (
          <StyledImagePlaceholder
            data-testid={RetailAccountsDropdownIds.EmptyLogo}
          >
            <StyledPlaceholder iconName="image" iconSize="small" />
          </StyledImagePlaceholder>
        )}
        <AccountLabel>
          {formatNameWithCode(accountName, accountId)}
        </AccountLabel>
      </>
    }
  />
)

export default AccountMenuTrigger
