import { useState } from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'

import { fromGlobalId } from 'utils/transformations/graphql'

import {
  getAccountId,
  getAccountLogoURL,
  getAccountProfileName,
  userIsInternal,
  userIsOwner,
} from 'store/currentUser/selectors'

import AccountMenuTrigger from './components/AccountMenuTrigger/AccountMenuTrigger'
import CommonSettings from './components/CommonSettings/CommonSettings'
import CurrentAccountSection from './components/CurrentAccountSection/CurrentAccountSection'
import OtherAccountsSection from './components/OtherAccountsSection/OtherAccountsSection'
import {
  MergeButton,
  OptionWrapper,
  OptionsContainer,
} from './retailerAccountsDropdown.ui'
import { useGetLiteRetailerAccountsInfo } from 'features/Navbar/NavbarLiteRetailer/NavbarLiteRetailer.queries'
import MergeAccountsModal from 'features/Navbar/NavbarRevamp/components/AccountsDropdown/MergeAccountsModal/MergeAccountsModal'
import NavbarDropdown from 'features/Navbar/NavbarRevamp/components/NavbarDropdown/NavbarDropdown'

const RetailAccountsDropdown = () => {
  const [isMergeModalOpen, setMergeModalOpen] = useState(false)
  const accountId = useSelector(getAccountId)
  const accountName = useSelector(getAccountProfileName)
  const isAccountOwner = useSelector(userIsOwner)
  const isInternalUser = useSelector(userIsInternal)
  const logoUrl = useSelector(getAccountLogoURL)

  const { userAccounts, accounts } = useGetLiteRetailerAccountsInfo()

  const { mergeAccounts } = useFlags()
  const hasAccountsToMerge =
    userAccounts.filter(
      (account: { assortmentPlanning: boolean }) => !account.assortmentPlanning,
    )?.length >= 0

  const canMergeAccounts =
    ((isAccountOwner && mergeAccounts) || isInternalUser) && hasAccountsToMerge

  return (
    <>
      <NavbarDropdown
        rightPosition
        trigger={
          <AccountMenuTrigger
            accountId={accountId}
            accountName={accountName}
            logoUrl={logoUrl}
          />
        }
      >
        <OptionsContainer>
          <CurrentAccountSection
            accountId={accountId}
            accounts={accounts}
            userAccounts={userAccounts}
          />
          {userAccounts.length > 1 && (
            <OtherAccountsSection
              accounts={accounts}
              userAccounts={userAccounts.filter(
                (userAccount) =>
                  fromGlobalId(userAccount.id)?.id !== String(accountId),
              )}
            />
          )}
          <CommonSettings />
          <OptionWrapper>
            {canMergeAccounts && (
              <MergeButton onClick={() => setMergeModalOpen(true)}>
                Merge Duplicate Accounts
              </MergeButton>
            )}
          </OptionWrapper>
        </OptionsContainer>
      </NavbarDropdown>
      {canMergeAccounts && isMergeModalOpen && (
        <MergeAccountsModal
          isOpen={isMergeModalOpen}
          setOpen={setMergeModalOpen}
          accounts={userAccounts}
          accountId={String(accountId)}
        />
      )}
    </>
  )
}

export default RetailAccountsDropdown
