import React from 'react'

import { useFlags } from '@joor/launchdarkly-react-client-sdk'
import { useSelector } from 'react-redux'
import styled from 'styled-components'

import {
  isAccountTypeRetailer,
  isAddOrCreateStylesRestricted,
  isLiteBrand,
  userCanViewDataPortal,
  userIsAdmin,
} from 'store/currentUser/selectors'

import {
  CREATE_LINESHEET,
  CREATE_STYLE,
  IMPORT_STYLES,
  INVENTORY,
  LINESHEETS,
  LINESHEETS_UPLOAD_IMAGES,
  NEW_CREATE_LINESHEET,
  SHOWROOM_COLLECTION_LIST,
  SHOWROOM_STYLEBOARD_ADD,
  SHOWROOM_STYLEBOARD_LIST,
  SHOWROOM_TEMPLATE_LIST,
  STAGING,
  STYLES,
  STYLE_SYNC,
  UPLOAD_DATA,
} from 'routes/paths'

import { IProductsDropdownSelectors } from '../../navbarRevamp.types'
import DropdownMenuGroup from '../DropdownMenuGroup/DropdownMenuGroup'
import DropdownMenuTextLink from '../DropdownMenuTextLink/DropdownMenuTextLink'
import NavbarDropdown from '../NavbarDropdown/NavbarDropdown'
import Trigger from '../Trigger/Trigger'
import { useImportStylesExperience } from './navbarProductsDropdown.hooks'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { ProductsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'
import {
  IMPORT_STYLES_KEY_PARAM,
  IMPORT_STYLES_VALUE_PARAM,
} from 'features/Styles/constants/constants'

const NavbarProductsDropdownContainer = styled.div`
  display: flex;
`

interface LinesheetGroupProps {
  newCollectionsEditAndCreate: boolean
  canViewDataPortal: boolean
  isLiteBrandAccount: boolean
}

const LinesheetGroup = ({
  newCollectionsEditAndCreate,
  canViewDataPortal,
  isLiteBrandAccount,
}: LinesheetGroupProps) => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={LINESHEETS}
      message={messages.manageLinesheets}
      id={ProductsDropdownIds.ProductsOptionsManageLinesheets}
      testId={ProductsDropdownIds.ProductsOptionsManageLinesheets}
    />
    {!isLiteBrandAccount && (
      <DropdownMenuTextLink
        to={
          newCollectionsEditAndCreate ? NEW_CREATE_LINESHEET : CREATE_LINESHEET
        }
        message={messages.createLinesheet}
        id={ProductsDropdownIds.ProductsOptionsCreateLinesheet}
        testId={ProductsDropdownIds.ProductsOptionsCreateLinesheet}
      />
    )}

    {(canViewDataPortal || isLiteBrandAccount) && (
      <DropdownMenuTextLink
        to={UPLOAD_DATA}
        message={messages.uploadData}
        id={ProductsDropdownIds.ProductsOptionsUploadData}
        testId={ProductsDropdownIds.ProductsOptionsUploadData}
      />
    )}
  </DropdownMenuGroup>
)

interface StylesGroupProps {
  restrictAddOrCreateStyles: boolean
  stylesPath: string
  isLiteBrandAccount: boolean
}

const StylesGroup = ({
  restrictAddOrCreateStyles,
  stylesPath,
  isLiteBrandAccount,
}: StylesGroupProps) => {
  const { newExperience, oldExperience } = useImportStylesExperience()

  return (
    <DropdownMenuGroup>
      <DropdownMenuTextLink
        to={stylesPath}
        message={messages.manageStyles}
        id={ProductsDropdownIds.ProductsOptionsManageStyles}
        testId={ProductsDropdownIds.ProductsOptionsManageStyles}
      />
      {!restrictAddOrCreateStyles && !isLiteBrandAccount && (
        <DropdownMenuTextLink
          to={CREATE_STYLE}
          message={messages.createStyle}
          id={ProductsDropdownIds.ProductsOptionsCreateStyle}
          testId={ProductsDropdownIds.ProductsOptionsCreateStyle}
        />
      )}
      {newExperience && (
        <DropdownMenuTextLink
          to={`${STYLES}?${IMPORT_STYLES_KEY_PARAM}=${IMPORT_STYLES_VALUE_PARAM}`}
          message={messages.uploadStyles}
          id={ProductsDropdownIds.ProductsOptionsBasicStyleUploader}
          testId={ProductsDropdownIds.ProductsOptionsBasicStyleUploader}
        />
      )}
      {oldExperience && (
        <DropdownMenuTextLink
          to={IMPORT_STYLES}
          message={messages.uploadStyles}
          id={ProductsDropdownIds.ProductsOptionsUploadStyle}
          testId={ProductsDropdownIds.ProductsOptionsUploadStyle}
        />
      )}
      {newExperience && (
        <DropdownMenuTextLink
          to={LINESHEETS_UPLOAD_IMAGES}
          message={messages.uploadImages}
          id={ProductsDropdownIds.ProductsOptionsUploadImages}
          testId={ProductsDropdownIds.ProductsOptionsUploadImages}
        />
      )}
    </DropdownMenuGroup>
  )
}

const InventoryGroup = () => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={INVENTORY}
      message={messages.inventory}
      id={ProductsDropdownIds.ProductsOptionsInventory}
      testId={ProductsDropdownIds.ProductsOptionsInventory}
    />
  </DropdownMenuGroup>
)

const LooksGroup = () => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={SHOWROOM_COLLECTION_LIST}
      message={messages.manageLooks}
      id={ProductsDropdownIds.ProductsOptionsManageLooks}
      testId={ProductsDropdownIds.ProductsOptionsManageLooks}
    />
    <DropdownMenuTextLink
      to={SHOWROOM_TEMPLATE_LIST}
      message={messages.createLook}
      id={ProductsDropdownIds.ProductsOptionsCreateLook}
      testId={ProductsDropdownIds.ProductsOptionsCreateLook}
    />
  </DropdownMenuGroup>
)

const StyleboardsGroup = () => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={SHOWROOM_STYLEBOARD_LIST}
      message={messages.manageStyleboards}
      id={ProductsDropdownIds.ProductsOptionsManageStyleboards}
      testId={ProductsDropdownIds.ProductsOptionsManageStyleboards}
    />
    <DropdownMenuTextLink
      to={SHOWROOM_STYLEBOARD_ADD}
      message={messages.createStyleboard}
      id={ProductsDropdownIds.ProductsOptionsCreateStyleboard}
      testId={ProductsDropdownIds.ProductsOptionsCreateStyleboard}
    />
  </DropdownMenuGroup>
)

const StagingSyncGroup = () => (
  <DropdownMenuGroup>
    <DropdownMenuTextLink
      to={STAGING}
      message={messages.staging}
      id={ProductsDropdownIds.ProductsOptionsManageStaging}
      testId={ProductsDropdownIds.ProductsOptionsManageStaging}
    />
    <DropdownMenuTextLink
      to={STYLE_SYNC}
      message={messages.styleSync}
      id={ProductsDropdownIds.ProductsOptionsManageStyleSync}
      testId={ProductsDropdownIds.ProductsOptionsManageStyleSync}
    />
  </DropdownMenuGroup>
)

const NavbarProductsDropdown = () => {
  const {
    canViewDataPortal,
    restrictAddOrCreateStyles,
    isRetailerAccount,
    isLiteBrandAccount,
    isAdmin,
  } = useSelector<object, IProductsDropdownSelectors>((state) => ({
    canViewDataPortal: userCanViewDataPortal(state),
    restrictAddOrCreateStyles: isAddOrCreateStylesRestricted(state),
    isRetailerAccount: isAccountTypeRetailer(state),
    isLiteBrandAccount: isLiteBrand(state),
    isAdmin: userIsAdmin(state),
  }))

  const { newCollectionsEditAndCreate, virtualShowroom } = useFlags()

  if (isRetailerAccount) {
    return null
  }

  return (
    <NavbarProductsDropdownContainer>
      <NavbarDropdown
        trigger={
          <Trigger
            label={messages.products}
            id={ProductsDropdownIds.ProductsTrigger}
          />
        }
      >
        <LinesheetGroup
          canViewDataPortal={canViewDataPortal}
          newCollectionsEditAndCreate={newCollectionsEditAndCreate}
          isLiteBrandAccount={isLiteBrandAccount}
        />
        <StylesGroup
          restrictAddOrCreateStyles={restrictAddOrCreateStyles}
          stylesPath={STYLES}
          isLiteBrandAccount={isLiteBrandAccount}
        />
        {!isLiteBrandAccount && <InventoryGroup />}
        {virtualShowroom && <LooksGroup />}
        {virtualShowroom && <StyleboardsGroup />}
        {isAdmin && <StagingSyncGroup />}
      </NavbarDropdown>
    </NavbarProductsDropdownContainer>
  )
}

export default NavbarProductsDropdown
