import { useState } from 'react'

import { HELP } from 'externalUrls'
import { useSelector } from 'react-redux'

import { accessZendeskLoggedIn } from 'utils/sdks/zendesk'
import { clearStorage } from 'utils/storage'

import { getUserId, userIsLoggedInAsAdmin } from 'store/currentUser/selectors'

import { ADMIN_LOGOUT, LOGOUT } from 'routes/paths'

import { OptionWrapper } from '../../retailerAccountsDropdown.ui'
import LanguageModal from 'features/Navbar/NavbarLiteRetailer/modals/LanguageModal/LanguageModal'
import MenuText from 'features/Navbar/NavbarRevamp/components/DropdownMenuTextLink/DropdownMenuText'
import DropdownMenuTextLink from 'features/Navbar/NavbarRevamp/components/DropdownMenuTextLink/DropdownMenuTextLink'
import messages from 'features/Navbar/NavbarRevamp/constants/navbarRevamp.messages'
import { RetailAccountsDropdownIds } from 'features/Navbar/NavbarRevamp/navbarRevamp.ids'

const CommonSettings = () => {
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false)
  const userId = useSelector(getUserId)
  const isLoggedInAsAdmin = useSelector(userIsLoggedInAsAdmin)

  return (
    <>
      <OptionWrapper>
        <MenuText
          message="Language"
          onClick={() => setIsLanguageModalOpen(true)}
          id={RetailAccountsDropdownIds.Language}
        />
      </OptionWrapper>
      <OptionWrapper>
        <DropdownMenuTextLink
          to={{ pathname: accessZendeskLoggedIn(HELP) }}
          message={messages.helpCenter}
          target="_blank"
          id={RetailAccountsDropdownIds.Help}
        />
      </OptionWrapper>
      {isLoggedInAsAdmin && (
        <OptionWrapper>
          <DropdownMenuTextLink
            to={ADMIN_LOGOUT}
            message={messages.switchToAdmin}
            onClick={() => clearStorage(`${userId}`)}
            id={RetailAccountsDropdownIds.SwitchTo}
          />
        </OptionWrapper>
      )}
      <OptionWrapper>
        <DropdownMenuTextLink
          to={LOGOUT}
          message={messages.logout}
          onClick={() => clearStorage(`${userId}`)}
          id={RetailAccountsDropdownIds.Logout}
        />
      </OptionWrapper>
      {isLanguageModalOpen && (
        <LanguageModal
          isOpen={isLanguageModalOpen}
          onClose={() => setIsLanguageModalOpen(false)}
        />
      )}
    </>
  )
}

export default CommonSettings
