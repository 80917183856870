import { useMutation } from '@apollo/client'

import {
  LiteRetailerAccountsInfoDocument,
  LiteRetailerConnectedAccountsDropdownDocument,
  UpdateRetailerPrimaryAccountDocument,
} from '__generated__/atlas-types'

import { atlasClient } from 'graphql/client'
import { atlas } from 'graphql/tags'

atlas`#graphql
  mutation UpdateRetailerPrimaryAccount(
    $input: UpdateRetailerPrimaryAccountInput!
  ) {
    UpdateRetailerPrimaryAccount(input: $input) {
      retailerId
    }
  }
`

export const useUpdateRetailerPrimaryAccount = () => {
  const [mutate, { loading, error }] = useMutation(
    UpdateRetailerPrimaryAccountDocument,
    {
      client: atlasClient,
    },
  )

  return {
    updateRetailerPrimaryAccount: (
      retailerId: string,
      setPrimaryAccount: boolean,
    ) => {
      return mutate({
        variables: {
          input: {
            retailerId,
            primaryAccount: setPrimaryAccount,
          },
        },
        refetchQueries: [
          {
            query: LiteRetailerConnectedAccountsDropdownDocument,
            variables: { retailerIds: [retailerId] },
          },
          {
            query: LiteRetailerAccountsInfoDocument,
            variables: { retailerIds: [retailerId] },
          },
        ],
      })
    },
    isUpdatingPrimaryAccountLoading: loading,
    error,
  }
}
