import { useEffect, useState } from 'react'

import { TextButton, useNotifications } from '@joor/design-system'
import debounce from 'lodash/debounce'
import find from 'lodash/find'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'

import { SwitchFromType } from '__generated__/atlas-types'

import { formatDropdownOptions, formatPriceTypeIds } from 'utils/formatters'
import { translateText } from 'utils/sdks/localize'
import { fromGlobalId, toGlobalId } from 'utils/transformations/graphql'

import { ALL_COLLECTIONS } from 'shop/formatters'
import { getAccountId } from 'store/currentUser/selectors'
import { setPageLoaderActive } from 'store/pageLoader/actions'
import { NONE_GROUP_BY_TAG, NONE_SORT_BY_TAG } from 'store/shop/constants'

import { Modal } from 'components/Core'
import Dropdown, { NO_RESULTS_MESSAGE } from 'components/Core/Dropdown/Dropdown'
import { HOME, PRODUCT_CATALOG } from 'routes/paths'

import { RetailerStartOrderIds } from '../StartOrderModal.ids'
import styles from '../StartOrderModal.less'
import { MINIMUM_CHARACTERS_QUERY } from '../StartOrderModalWrapper.queries'
import { COLLECTIONS_KEY } from 'containers/ProductCatalog/CatalogFilters/filters'
import { useOldSetSelectedAccount } from 'features/Navbar/NavbarRevamp/components/AccountsDropdown/accountsDropdown.hooks'

export const REDIRECT_AFTER_SWITCH = 'REDIRECT_AFTER_SWITCH'

export const DEBOUNCE_TIME = 500

export const deselectAllFilters = (args) => {
  const {
    toggleDeselectAllFilters,
    setSearchText,
    applySelectedFilters,
    setGroupByTagInfo,
    setSortByTagInfo,
  } = args
  toggleDeselectAllFilters()
  applySelectedFilters()
  setSearchText('')
  setGroupByTagInfo(NONE_GROUP_BY_TAG)
  setSortByTagInfo(NONE_SORT_BY_TAG)
}

export const createOrder = (args) => {
  const {
    history,
    selectedBrandId,
    selectedCollectionId,
    connectedAccounts,
    connectedAccountsData,
    setFilter,
    setBrand,
    collections,
    selectedPriceTypeId,
    priceTypes,
    setOrderPriceType,
    setPriceTypes,
    saveNewOrderGroupId,
    doors,
    setDoors,
    selectedDoorIds,
    trackStartOrderModalSubmitted,
  } = args

  trackStartOrderModalSubmitted?.({
    brandOptions: connectedAccounts,
    collectionOptions: collections,
    selectedCollectionId,
  })
  let brand = {}
  if (Boolean(connectedAccountsData?.length)) {
    const selectedBrand = find(
      connectedAccountsData,
      (connectedAccount) => connectedAccount.id === selectedBrandId,
    )
    brand = {
      key: selectedBrand.id,
      text: selectedBrand.profileName,
    }
  } else {
    brand = find(
      connectedAccounts,
      (connectedAccount) => connectedAccount.key === selectedBrandId,
    )
  }

  if (selectedDoorIds?.length) {
    const selectedDoors = selectedDoorIds.map((selectDoorId) =>
      doors.find((door) => door.value === selectDoorId),
    )
    setDoors(selectedDoors)
  } else {
    setDoors([])
  }

  setBrand({ id: brand.key, name: brand.text })

  if (selectedCollectionId === ALL_COLLECTIONS) {
    setFilter({ key: COLLECTIONS_KEY })
  } else {
    const selectedCollection = find(
      collections,
      (collection) => collection.key === selectedCollectionId,
    )
    setFilter({ key: COLLECTIONS_KEY, value: selectedCollection })
  }

  const selectedPriceType =
    priceTypes.length === 1
      ? priceTypes[0]
      : priceTypes.find(
          ({ id: priceTypeId }) => priceTypeId === selectedPriceTypeId,
        )
  setOrderPriceType(selectedPriceType)
  setPriceTypes(priceTypes)
  saveNewOrderGroupId()

  history.push(PRODUCT_CATALOG)
}

export const getSelectedPriceType = ({
  priceTypes,
  preselectedPriceTypeId,
  setSelectedPriceTypeId,
}) => {
  if (preselectedPriceTypeId?.length) return
  setSelectedPriceTypeId(priceTypes?.[0]?.id ?? null)
}

export const handleBrandChange = (args) => {
  const {
    value,
    setSelectedBrandId,
    setSelectedCollectionId,
    setBrandId,
    changeCollectionSearchText,
    setSelectedPriceTypeId,
    priceTypes,
    preselectedPriceTypeId,
    setMoreSelectionsOpen,
  } = args
  setBrandId(value)
  setSelectedBrandId(value)
  setSelectedCollectionId(ALL_COLLECTIONS)
  changeCollectionSearchText('')
  getSelectedPriceType({
    priceTypes,
    setSelectedPriceTypeId,
    preselectedPriceTypeId,
  })
  setMoreSelectionsOpen(false)
}

export const handleSearchBrand = debounce((args) => {
  const { changeBrandSearchText, searchQuery } = args
  changeBrandSearchText(searchQuery)
}, DEBOUNCE_TIME)

export const handleSearchCollection = debounce((args) => {
  const { changeCollectionSearchText, searchQuery } = args
  changeCollectionSearchText(searchQuery)
}, DEBOUNCE_TIME)

const getPriceTypeOptions = (priceTypes) =>
  formatDropdownOptions(
    formatPriceTypeIds(priceTypes),
    undefined,
    false,
    'notranslate',
  )

const StyledTextButton = styled(TextButton)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 16px 24px 0px 0;
`

const StyledSection = styled.section`
  margin-top: 16px;
`

const RetailerStartOrderModal = (props) => {
  const accountId = useSelector(getAccountId)
  const setSelectedAccount = useOldSetSelectedAccount()
  const dispatch = useDispatch()
  const { addToasts } = useNotifications()
  const [selectedBrandId, setSelectedBrandId] = useState(
    props?.preselectedBrand?.id || null,
  )
  const [selectedCollectionId, setSelectedCollectionId] = useState(
    ALL_COLLECTIONS,
  )
  const [selectedPriceTypeId, setSelectedPriceTypeId] = useState(
    props?.preselectedPriceType?.id || null,
  )
  const [selectedDoorIds, setSelectedDoorIds] = useState(
    props?.preselectedDoors || [],
  )
  const [unconnectedAccountId, setUnconnectedAccountId] = useState(null)
  const [moreSelectionsOpen, setMoreSelectionsOpen] = useState(false)

  const {
    applySelectedFilters,
    arePriceTypesReady,
    changeBrandSearchText,
    changeCollectionSearchText,
    collections,
    collectionsLoading,
    connectedAccounts,
    connectedAccountsData,
    connectedAccountsQueryLoading,
    preselectedBrand,
    disableBrandOptionsDropdown,
    doorIdsLoading,
    doors,
    forceComplete,
    getDoorIds,
    history,
    modalTitle,
    onClose,
    onPrimaryButtonClick,
    preselectedPriceType,
    priceTypes,
    priceTypesLoading,
    saveNewOrderGroupId,
    selectCollection,
    setBrand,
    setBrandId,
    setDoors,
    setFilter,
    setGroupByTagInfo,
    setOrderPriceType,
    setPriceTypes,
    setSearchText,
    setSortByTagInfo,
    toggleDeselectAllFilters,
    trackStartOrderModalSubmitted,
    trackStartOrderModalCancelled,
  } = props

  const primaryDisabled = !selectedBrandId || doorIdsLoading
  const createOrderProps = {
    history,
    selectedBrandId,
    selectedCollectionId,
    connectedAccounts,
    connectedAccountsData,
    setFilter,
    setBrand,
    collections,
    priceTypes,
    selectedPriceTypeId,
    setOrderPriceType,
    setPriceTypes,
    saveNewOrderGroupId,
    doors,
    setDoors,
    selectedDoorIds,
    trackStartOrderModalSubmitted,
  }
  const deselectProps = {
    toggleDeselectAllFilters,
    applySelectedFilters,
    setSearchText,
    setGroupByTagInfo,
    setSortByTagInfo,
    setDoors,
  }

  useEffect(() => {
    if (selectedBrandId && !unconnectedAccountId) {
      getDoorIds({
        variables: {
          brandId: toGlobalId('Brand', fromGlobalId(selectedBrandId).id),
        },
      })
    }
  }, [selectedBrandId, getDoorIds, unconnectedAccountId])

  useEffect(() => {
    if (!doorIdsLoading && doors?.length === 1 && !unconnectedAccountId) {
      setSelectedDoorIds([doors[0].key])
    }
  }, [doorIdsLoading, doors, unconnectedAccountId])

  useEffect(
    () => () => {
      setBrandId()
      changeBrandSearchText('')
      changeCollectionSearchText('')
    },
    [],
  )

  useEffect(() => {
    getSelectedPriceType({
      priceTypes,
      preselectedPriceTypeId: preselectedPriceType?.id,
      setSelectedPriceTypeId,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    arePriceTypesReady,
    preselectedPriceType,
    priceTypes.length,
    selectedBrandId,
  ])

  const brandDropdownOptions = preselectedBrand
    ? [
        {
          className: 'notranslate',
          key: preselectedBrand?.id,
          text: preselectedBrand?.name,
          value: preselectedBrand?.id,
        },
      ]
    : connectedAccounts

  const priceTypeOptions = getPriceTypeOptions(priceTypes)

  const customOnPrimaryButtonClick = () => {
    const brand = brandDropdownOptions?.find(
      (account) => account.key === selectedBrandId,
    )
    const selectedDoors = doors?.filter((door) =>
      selectedDoorIds.includes(door.key),
    )
    onPrimaryButtonClick({
      brand,
      priceType: priceTypes?.find((pt) => pt?.id === selectedPriceTypeId),
      doors: selectedDoors,
    })
  }

  const handleClose = () => {
    trackStartOrderModalCancelled()
    onClose()
  }

  const handleRedirect = () => {
    const retailerId = toGlobalId('Retailer', unconnectedAccountId)
    setSelectedAccount({
      variables: {
        input: {
          account_id: retailerId,
          switch_from: SwitchFromType.START_NEW_ORDER,
        },
      },
    }).then((response) => {
      if (response.data.setSelectedAccount?.success) {
        dispatch(setPageLoaderActive(true))
        window.localStorage.setItem(
          REDIRECT_AFTER_SWITCH,
          JSON.stringify({
            path: PRODUCT_CATALOG,
            store: {
              brand: {
                id: selectedBrandId,
                displayName: connectedAccountsData.find(
                  (el) => el.retailerId === unconnectedAccountId,
                ).profileName,
              },
            },
          }),
        )
        window.location.href = HOME
      } else {
        dispatch(setPageLoaderActive(true))
        addToasts([
          {
            title: 'Redirection issue',
            description: 'There was an error setting the selected account',
            type: 'error',
          },
        ])
      }
    })
  }

  const getSelectedBrandValue = () =>
    selectedBrandId
      ? `${selectedBrandId}_${unconnectedAccountId || accountId}`
      : selectedBrandId

  return (
    <Modal
      id={RetailerStartOrderIds.ModalTitle}
      title={modalTitle || 'Start an Order'}
      primaryActionLabel="Start an Order"
      primaryActionOnClick={() => {
        if (onPrimaryButtonClick) {
          customOnPrimaryButtonClick()
        } else {
          if (unconnectedAccountId) {
            handleRedirect()
          } else {
            deselectAllFilters(deselectProps)
            createOrder(createOrderProps)
          }
        }
      }}
      primaryDisabled={primaryDisabled}
      open
      onClose={handleClose}
      className={styles.StartOrder}
      secondaryActionHidden={forceComplete}
      hideCloseButton={forceComplete}
      primaryActionId={RetailerStartOrderIds.AcceptButton}
      secondaryActionId={RetailerStartOrderIds.CancelButton}
      idCloseButton={RetailerStartOrderIds.CloseButton}
    >
      <label
        id={RetailerStartOrderIds.BrandOptions}
        htmlFor="brandOptions"
        className={styles.label}
      >
        <span className={styles.required}>*</span>
        Search for a brand
      </label>
      <span className="notranslate">
        <Dropdown
          loading={connectedAccountsQueryLoading}
          id="brandOptions"
          onChange={(_, { value }) => {
            const selectedBrandId = value.split('_')[0]
            const selectedRetailerId = parseInt(value.split('_')[1], 10)
            const selectedBrandAccount = connectedAccountsData.find(
              (connectedAccountData) =>
                connectedAccountData.id === selectedBrandId &&
                connectedAccountData.retailerId === selectedRetailerId,
            )
            const isCurrentAccount =
              selectedBrandAccount?.retailerId === accountId ||
              !Boolean(selectedBrandAccount?.retailerId)

            setUnconnectedAccountId(
              isCurrentAccount ? null : selectedBrandAccount.retailerId,
            )
            handleBrandChange({
              value: selectedBrandId,
              setSelectedBrandId,
              setSelectedCollectionId,
              setBrandId,
              changeCollectionSearchText,
              setSelectedPriceTypeId,
              getDoorIds,
              priceTypes,
              preselectedPriceTypeId: preselectedPriceType?.id,
              setMoreSelectionsOpen,
            })
          }}
          onSearchChange={(_, { searchQuery }) =>
            handleSearchBrand({ changeBrandSearchText, searchQuery })
          }
          // TODO(ch62906): we should add onClose prop to check if value is not defined.
          // If that happens, we should reset the selectedBrandId
          options={brandDropdownOptions}
          placeholder={translateText('Start typing to search...')}
          noResultsMessage={translateText(NO_RESULTS_MESSAGE)}
          search
          value={getSelectedBrandValue()}
          minCharacters={MINIMUM_CHARACTERS_QUERY}
          className={selectedBrandId ? 'notranslate' : ''}
          disabled={disableBrandOptionsDropdown}
        />
      </span>
      {moreSelectionsOpen && (
        <StyledSection>
          {selectCollection && selectedBrandId && !unconnectedAccountId && (
            <>
              <label
                id={RetailerStartOrderIds.CollectionTitle}
                htmlFor="collectionOptions"
                className={styles.label}
              >
                Search for a linesheet
              </label>
              <span className="notranslate">
                <Dropdown
                  id="collectionOptions"
                  onChange={(_, { value }) => setSelectedCollectionId(value)}
                  onSearchChange={(_, { searchQuery }) =>
                    handleSearchCollection({
                      changeCollectionSearchText,
                      searchQuery,
                    })
                  }
                  placeholder={translateText('Start typing to search...')}
                  options={collections}
                  search
                  value={selectedCollectionId}
                  className={
                    selectedCollectionId &&
                    selectedCollectionId !== ALL_COLLECTIONS
                      ? 'notranslate'
                      : ''
                  }
                  disabled={!selectedBrandId || unconnectedAccountId}
                  loading={collectionsLoading}
                />
              </span>
            </>
          )}
          {priceTypes.length > 0 && !unconnectedAccountId && (
            <>
              <label
                id={RetailerStartOrderIds.PriceTypeTitle}
                key="priceTypeOptionsLabel"
                htmlFor="priceTypeOptions"
                className={styles.label}
              >
                Select order price type
              </label>
              <span className="notranslate">
                <Dropdown
                  key="priceTypeOptionsDropdown"
                  id="priceTypeOptions"
                  onChange={(_, { value }) => setSelectedPriceTypeId(value)}
                  options={priceTypeOptions}
                  value={selectedPriceTypeId}
                  loading={priceTypesLoading}
                  disabled={unconnectedAccountId}
                />
              </span>
            </>
          )}
          {doors.length > 0 &&
            !unconnectedAccountId && [
              <label
                id={RetailerStartOrderIds.DoorTitle}
                key="doorOptionsLabel"
                htmlFor="doorOptions"
                className={styles.label}
              >
                Select door(s)
              </label>,
              <span className="notranslate" key="doorOptionsSpan">
                <Dropdown
                  key="doorOptionsDropdown"
                  data-testid="doorOptionsDropdown"
                  id="doorOptions"
                  onChange={(_, { value }) => {
                    setSelectedDoorIds(value)
                  }}
                  options={doors}
                  placeholder="Select one"
                  noResultsMessage={translateText(NO_RESULTS_MESSAGE)}
                  multiple
                  value={selectedDoorIds}
                  disabled={unconnectedAccountId}
                />
              </span>,
            ]}
        </StyledSection>
      )}
      <StyledTextButton
        id={
          !moreSelectionsOpen
            ? RetailerStartOrderIds.MoreOptions
            : RetailerStartOrderIds.FewerOptions
        }
        data-testid="more-selections-button"
        onClick={() => setMoreSelectionsOpen(!moreSelectionsOpen)}
        disabled={unconnectedAccountId || !selectedBrandId}
      >
        {moreSelectionsOpen ? 'Fewer options' : 'More options'}
      </StyledTextButton>
    </Modal>
  )
}

RetailerStartOrderModal.propTypes = {
  preselectedBrand: PropTypes.object,
  preselectedPriceType: PropTypes.object,
  preselectedDoors: PropTypes.array,
  disableBrandOptionsDropdown: PropTypes.bool,
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  connectedAccountsQueryLoading: PropTypes.bool,
  connectedAccounts: PropTypes.array,
  connectedAccountsData: PropTypes.array,
  collectionsLoading: PropTypes.bool,
  collections: PropTypes.array,
  selectCollection: PropTypes.bool,
  changeBrandSearchText: PropTypes.func,
  setBrandId: PropTypes.func,
  setBrand: PropTypes.func,
  toggleDeselectAllFilters: PropTypes.func,
  applySelectedFilters: PropTypes.func,
  setSearchText: PropTypes.func,
  setGroupByTagInfo: PropTypes.func,
  setFilter: PropTypes.func,
  changeCollectionSearchText: PropTypes.func,
  priceTypes: PropTypes.array,
  priceTypesLoading: PropTypes.bool,
  setOrderPriceType: PropTypes.func,
  setPriceTypes: PropTypes.func,
  arePriceTypesReady: PropTypes.bool,
  saveNewOrderGroupId: PropTypes.func.isRequired,
  forceComplete: PropTypes.bool,
  setDoors: PropTypes.func,
  doors: PropTypes.array,
  doorIdsLoading: PropTypes.bool,
  getDoorIds: PropTypes.func,
  onPrimaryButtonClick: PropTypes.func,
  modalTitle: PropTypes.string,
  trackStartOrderModalSubmitted: PropTypes.func,
  trackStartOrderModalCancelled: PropTypes.func,
}

RetailerStartOrderModal.defaultProps = {
  onClose: () => {},
  connectedAccountsQueryLoading: false,
  connectedAccounts: [],
  connectedAccountsData: [],
  collectionsLoading: false,
  collections: [],
  selectCollection: true,
  changeBrandSearchText: () => {},
  setBrandId: () => {},
  setBrand: () => {},
  toggleDeselectAllFilters: () => {},
  applySelectedFilters: () => {},
  setSearchText: () => {},
  setGroupByTagInfo: () => {},
  setFilter: () => {},
  changeCollectionSearchText: () => {},
  priceTypes: [],
  priceTypesLoading: false,
  setOrderPriceType: () => {},
  setPriceTypes: () => {},
  arePriceTypesReady: false,
  forceComplete: false,
  setDoors: () => {},
  doors: [],
  doorIdsLoading: false,
  getDoorIds: () => {},
  trackStartOrderModalSubmitted: () => {},
  trackStartOrderModalCancelled: () => {},
}

export default RetailerStartOrderModal
