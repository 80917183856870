import { useState } from 'react'

import { ObservableQuery } from '@apollo/client'
import { Icon } from '@joor/design-system'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'

import { LGConnectionStatus } from '__generated__/atlas-types'

import {
  getAccountId,
  isAccountTypeRetailer,
} from 'store/currentUser/selectors'

import HeaderCTA from './HeaderCTA'
import { AcceptConnectionModal } from 'features/LeadGen/components/AcceptConnectionModal/AcceptConnectionModal'
import { ConnectModal } from 'features/LeadGen/components/ConnectModal/ConnectModal'
import { MessageModal } from 'features/LeadGen/components/MessageModal/MessageModal'

type HeaderProps = {
  logoUrl: string
  retailerName: string
  retailerId: string
  handleClick: () => void
  connectionStatus?: LGConnectionStatus | null
  refetchProfileData: ObservableQuery['refetch']
  autoConnect?: boolean | null
}

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100vw;
  gap: 16px;
  padding: 32px;
  > button {
    margin-bottom: 16px;
  }
  @media only screen and (min-width: 768px) {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    > button {
      margin-bottom: 0;
    }
  }
`
const StyledRetailerInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`
const StyledRetailerName = styled.div`
  font-family: var(--font-family-alpha);
  font-size: var(--size-font-xxlarge);
  font-style: normal;
  font-weight: var(--font-weight-light);
  line-height: var(--line-height-60);
  letter-spacing: var(--letter-spacing-widest);
`
const LogoContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`
const LogoOverlay = styled.div`
  height: 92px;
  width: 92px;
  border-radius: 5px;
  position: absolute;
  top: 0;
  background: #2d343914;
`
const StyledLogo = styled.img`
  height: 92px;
  width: 92px;
  min-width: 92px;
  background-position: center;
  border-radius: 5px;
  object-fit: contain;
  object-position: center;
`
const StyledImagePlaceholder = styled.div`
  height: 92px;
  min-height: 92px;
  min-width: 92px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid var(--color-primary-400);
  background-color: var(--color-primary-100);
`
const StyledPlaceholder = styled(Icon)`
  > svg > g {
    fill: var(--color-primary-400);
  }
`
const Header = ({
  logoUrl,
  retailerName,
  retailerId,
  handleClick,
  connectionStatus,
  refetchProfileData,
  autoConnect,
}: HeaderProps) => {
  const [messageModalOpen, setMessageModalOpen] = useState<boolean>(false)
  const [connectModalOpen, setConnectModalOpen] = useState<boolean>(false)
  const [
    acceptConnectionModalOpen,
    setAcceptConnectionModalOpen,
  ] = useState<boolean>(false)

  const isRetailerAccount = useSelector(isAccountTypeRetailer)
  const { accountId: profileAccountId } = useParams<{ accountId: string }>()
  const loggedInUserId = useSelector(getAccountId)?.toString()
  const isProfileOwner = profileAccountId === loggedInUserId

  return (
    <StyledHeader>
      <StyledRetailerInfo>
        {logoUrl ? (
          <LogoContainer>
            <StyledLogo src={logoUrl} alt="retailer-logo" />
            <LogoOverlay />
          </LogoContainer>
        ) : (
          <StyledImagePlaceholder>
            <StyledPlaceholder iconName="image" />
          </StyledImagePlaceholder>
        )}
        <StyledRetailerName className="notranslate">
          {retailerName}
        </StyledRetailerName>
      </StyledRetailerInfo>
      <HeaderCTA
        retailerName={retailerName}
        isRetailerAccount={isRetailerAccount}
        isProfileOwner={isProfileOwner}
        connectionStatus={connectionStatus}
        handleEditClick={handleClick}
        handleAcceptClick={() =>
          setAcceptConnectionModalOpen(!acceptConnectionModalOpen)
        }
        handleMessageClick={() => setMessageModalOpen(!messageModalOpen)}
        handleConnectClick={() => setConnectModalOpen(!connectModalOpen)}
      />
      {messageModalOpen && (
        <MessageModal
          retailerId={retailerId}
          retailerName={retailerName}
          onClose={() => {
            setMessageModalOpen(false)
            refetchProfileData()
          }}
          isOpen={messageModalOpen}
        />
      )}
      {connectModalOpen && (
        <ConnectModal
          retailerName={retailerName}
          retailerId={retailerId}
          onClose={() => {
            setConnectModalOpen(false)
            refetchProfileData()
          }}
          isOpen={connectModalOpen}
          autoConnect={autoConnect}
        />
      )}
      {acceptConnectionModalOpen && (
        <AcceptConnectionModal
          isOpen={acceptConnectionModalOpen}
          retailerName={retailerName}
          retailerId={retailerId}
          onClose={() => {
            setAcceptConnectionModalOpen(false)
          }}
          refetchProfileData={refetchProfileData}
        />
      )}
    </StyledHeader>
  )
}

export default Header
