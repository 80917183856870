import { ReactComponent as JOORLogo } from 'assets/images/logo_joor.svg'

import { HOME } from 'routes/paths'

import {
  HomeLink,
  NavbarHeader,
  NavbarRevampContainer,
  RightOptionsContainer,
} from '../Navbar.ui'
import NavbarCartDropdown from '../NavbarRevamp/components/CartDropdown/CartDropdown'
import MessageMenuOption from '../NavbarRevamp/components/MessageMenuOption/MessageMenuOption'
import NavbarOrdersDropdown from '../NavbarRevamp/components/OrdersDropdown/NavbarOrdersDropdown'
import ProLiteIndicator from '../NavbarRevamp/components/ProLiteIndicator/ProLiteIndicator'
import ShopDropdown from '../NavbarRevamp/components/ShopDropdown/ShopDropdown'
import { HomeIds } from '../NavbarRevamp/navbarRevamp.ids'
import NavbarExploreDropdown from './components/ExploreDropdown/ExploreDropdown'
import RetailAccountsDropdown from './components/RetailerAccountsDropdown/RetailerAccountsDropdown'

export const NavbarLiteRetailer = () => {
  return (
    <NavbarRevampContainer>
      <NavbarHeader data-testid="navbar">
        <ProLiteIndicator />
        <HomeLink to={HOME} data-testid={HomeIds.HomeLinkTrigger}>
          <JOORLogo />
        </HomeLink>
        <ShopDropdown />
        <NavbarOrdersDropdown />
        <NavbarExploreDropdown />
        <RightOptionsContainer>
          <RetailAccountsDropdown />
          <MessageMenuOption />
          <NavbarCartDropdown />
        </RightOptionsContainer>
      </NavbarHeader>
    </NavbarRevampContainer>
  )
}
