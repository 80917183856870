const messages = {
  manageBrandDetails: 'Manage Brand Details',
  adminIntegrationKellwood: 'Admin Integration (Kellwood)',
  adminIntegrationRLM: 'Admin Integration (RLM)',
  allocation: 'Allocation',
  archived: 'Archived',
  assortments: 'Assortments',
  attributeSettings: 'Attribute Settings',
  budget: 'Budget',
  brandNotes: 'Brand Notes',
  browseCatalog: 'Browse Catalog',
  cart: 'Cart',
  cartView: 'View Cart',
  classicReports: 'Classic Order Reports',
  colors: 'Colors',
  connections: 'Connections',
  connectedBrands: 'Connected Brands',
  connectionsRequests: 'Connection Requests',
  connectionsRequestsReceived: 'Connection Requests - Received',
  connectionsRequestsSent: 'Connection Requests - Sent',
  createLinesheet: 'Create a Linesheet',
  createLook: 'Create a Look',
  createStyle: 'Create a Style',
  createStyleboard: 'Create a Styleboard',
  customReports: 'Custom Reports',
  deliveryReport: 'Delivery Report',
  discover: 'Discover',
  emptyCart: 'You currently do not have any orders in progress',
  externalPayments: 'Manage External Payments',
  exploreBrands: 'Explore Brands',
  favorites: 'Favorites',
  findNewBrands: 'Find New Brands',
  findNewRetailers: 'Find New Retailers',
  helpCenter: 'Help Center',
  integrationSettings: 'Integration Settings',
  interestedInYou: 'Interested in You',
  inventory: 'Inventory',
  inventorySettings: 'Inventory Settings',
  isJoorProFeature: 'This is a JOOR Pro feature',
  joorPayReports: 'JOOR Pay Reports',
  learnMore: 'Learn More',
  linesheets: 'Linesheets',
  logout: 'Log Out',
  lookbooks: 'Manage Lookbooks',
  looks: 'Looks',
  manageConnections: 'Manage Connections',
  manageJoorPayOrders: 'Manage JOOR Pay Orders',
  manageLinesheets: 'Manage Linesheets',
  manageLooks: 'Manage Looks',
  manageOrders: 'Manage Orders',
  manageProfile: 'Manage Profile',
  manageStyleboards: 'Manage Styleboards',
  manageStyles: 'Manage Styles',
  manageWorkspaces: 'Manage Workspaces',
  messages: 'Messages',
  myConnections: 'My Connections',
  mySettings: 'My Settings',
  accountSettings: 'Account Settings',
  orders: 'Orders',
  ordersSettings: 'Orders Settings',
  passport: 'Passport',
  paymentPromo: 'Receive Payments Faster & Easier with JOOR Pay',
  paymentSettings: 'Payment Settings',
  payments: 'Payments',
  pendingConnections: 'Pending Connections',
  permissionSettings: 'Permission Settings',
  photoGallery: 'Photo Gallery',
  press: 'Manage Press',
  productionReport: 'Production',
  products: 'Products',
  profile: 'Profile',
  profileViews: 'Profile Views',
  prospects: 'Prospects',
  reporting: 'Reporting',
  reports: 'Reports',
  retailers: 'Retailers',
  retailerSettings: 'Retailer Settings',
  salesSummary: 'Sales Summary',
  settings: 'Settings',
  shop: 'Shop',
  ssoSettings: 'SSO Settings',
  staging: 'Staging',
  startAnOrder: 'Start an Order',
  styleDetail: 'Style Detail',
  styleSettings: 'Style Settings',
  styleSync: 'Style Sync',
  styleSyncSettings: 'Style Sync Settings',
  styleboards: 'Styleboards',
  styles: 'Styles',
  switchToAdmin: 'Switch to Admin',
  thirdPartySettings: 'Third Party Settings',
  total: 'Total',
  units: 'Units',
  uploadData: 'Upload Data',
  uploadExcelOrders: 'Upload Excel Orders',
  uploadImages: 'Upload Images',
  uploadStyles: 'Upload Styles',
  viewProfile: 'View Profile',
  yourJOORUrl: 'Your JOOR URL',
  workspaces: 'Workspaces',
  viewAll: 'View All Cart Orders',
}

export default messages
